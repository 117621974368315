html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
  font-size: 16px; /* you can chose any size here 16 is default */

  @media screen and (max-width: 767px) {
    font-size: 87.5%;
  }

  @media screen and (max-width: 600px) {
    font-size: 75%;
  }
}

body {
  margin: 0;
  font-family:
    "Roboto",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
}

body,
html {
  height: 100%;
  background-image: linear-gradient(#f5f5f9, #dfdfec);
}

#root {
  height: 100%;
}

.leaflet-container {
  width: 100%;
  height: 400px;
}

.custom-marker-cluster {
  border: none;
  width: 0px;
  height: 0px;
}

.fullscreen-enabled {
  background-image: linear-gradient(#f5f5f9, #dfdfec);
}

#reportViewer1 {
  overflow: auto;
  height: 500px;
}
